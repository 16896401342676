/* Header navigation item */
.header .nav-item{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center; 
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;  
    -ms-flex-align: center;
    align-items: center;
}   

/* Header dropdown menu */
.header .dropdown-menu{
    display: inline-block;
    font-size: 2rem;
    color: var(--white);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    gap: 0.5rem;
}

/* Header dropdown menu button */
.header .dropdown-menu .nav-btn{
    font-family: 'Andika', sans-serif;
    display: inline-block;
    font-size: 1.8rem;
    color: var(--white);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    gap: 0.5rem;
}

/* Header dropdown menu Icon */
.header .dropdown-menu .icon{
    font-size: 2rem;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Header dropdown content */
.header .dropdown-content{
    position: absolute;
    top: 100%;
    left: 0;
    color: var(--black);
    background-color: var(--white);
    padding: 0;
    width: 20rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: none;
    -webkit-transform: scale(0); 
            transform: scale(0);
    -webkit-transition:  all 0.5s;
    -moz-transition:  all 0.5s;
    -o-transition:  all 0.5s;
    transition:  all 0.5s;
    z-index: 1000;
    border-bottom: 0.2rem solid var(--main-color);
}

/* Header dropdown content link */
.header .dropdown-content a{
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    cursor: pointer;
}

/* Header dropdown content link, On Hover */
.header .dropdown-content a:hover{
    color: var(--white);
    background-color: var(--main-color);
}

/* Header dropdown menu button, On navigation item Hover */
.header .nav-item:hover .nav-btn{
    color: var(--main-color);
}

/* Header dropdown menu icon, On navigation item Hover */
.header .nav-item:hover .icon{
    color: var(--main-color);
}

/* Header dropdown content, On navigation item Hover */
.header .nav-item:hover .dropdown-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale(1);
            transform: scale(1);
}

/* Header Rent Car button */
.header .btn{
    font-size: 1.6rem;
    padding: 0.7rem 1.5rem;  
}

/* Header Rent Car button, On Hover */
.header .btn:hover{
    background-color: var(--main-color);
}