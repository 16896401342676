/* Logo container */
.logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center;
    gap: 1rem; 
} 

/* Logo image */
.logo img{
    height: 5.5rem;
    object-fit: cover;
}

/* Logo Main Name */
.logo h3{
    font-size: 3.5rem;
    font-weight: 600;
    color: var(--white);
    line-height: 1;
}

/* Logo Main Name (Sub Text) */ 
.logo h3 span{
    color: var(--main-color);
}

/* Logo Text Detils */
.logo p{
    font-size: 1.5rem;
    color: var(--white);
    line-height: 1;
}