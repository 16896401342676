/* Services Container*/
.services .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1rem;
}
  
/* Service Card */  
.service-item{ 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 1.5rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Service Heading */
.service-item h3{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 1rem;
}

/* Service Heading, On Hover */
.service-item h3:hover{
    color: var(--main-color);
}

/* Service Paragraph */
.service-item p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.7;
}

/* Service Icon */
.service-item .icon{
    height: 5rem;
    font-size: 3rem;
    padding: 1rem;
    color: var(--white);
    background-color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}