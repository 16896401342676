/* Register Terms */
.register form .terms{
  display: -webkit-box;
  display: -ms-flexbox;  
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;  
  gap: .5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;  
} 
 
/* Register Terms label */
.register form .terms label{
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
  
/* Register Terms label span */ 
.register form .terms label span{
  color: var(--main-color);
}