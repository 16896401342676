/* Car Search */
.carSearch{
  background: var(--black);
}

/* Car Search Heading */ 
.carSearch h4{
  font-size: 4rem; 
  font-weight: 600; 
  color: var(--white);
  text-align: center; 
}

/* Car Search Form */
.car-search { 
  padding: 2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap; 
      flex-wrap: wrap;
  gap: 1.5rem;
}

/* Car Search Box */
.car-search .box {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25rem; 
      flex: 1 1 25rem; 
}

/* Input field */
.car-search .input-field {
  background-color: var(--white);
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  padding: 1rem;
  font-size: 1.7rem;
  color: var(--black);
  text-transform: none; 
  min-height: 4.5rem;
}

/* Input field, Focused */
.car-search .input-field:focus {
  border-color: var(--main-color);
  -webkit-box-shadow: 0 0 0 1px var(--main-color);
          box-shadow: 0 0 0 1px var(--main-color);
}
 
/* Input field, placeholder */
.car-search .box input::-webkit-input-placeholder {
  text-transform: capitalize;
}
.car-search .box input::-moz-placeholder {
  text-transform: capitalize;
}
.car-search .box input:-ms-input-placeholder {
  text-transform: capitalize;
}
.car-search .box input::placeholder {
  text-transform: capitalize;
}

/* Submit button */
.car-search .btn {
  width: 100%;
  display: block;
  text-align: center;
  border: 0.2rem solid var(--white);
  border-radius: 0.5rem;
  min-height: 5rem;
}


/*----- react-select styling -----*/

/* Select wrapper */
.car-search .custom-select .custom-select-prefix__control {
  border: 1px solid rgba(0,0,0,0.2) !important;
  font-size: 1.5rem;
  border-radius: 0.4rem;
  min-height: 4.5rem;
}

/* Select Value */
.car-search .custom-select .custom-select-prefix__single-value {
  color: var(--grey) !important;
}

/* Focused Select wrapper */
.car-search .custom-select-prefix__control--is-focused {
  border-color: var(--main-color) !important;
  -webkit-box-shadow: 0 0 0 1px var(--main-color) !important;
          box-shadow: 0 0 0 1px var(--main-color) !important;
}

/* Select option */
.car-search .custom-select .custom-select-prefix__option {
  cursor: pointer;
  font-size: 1.5rem;
} 

/* select option hovered */
.car-search .custom-select .custom-select-prefix__option:hover {
  color: var(--white);
  background-color: var(--main-color);
}

/* select option selected */
.car-search .custom-select .custom-select-prefix__option--is-selected {
  color: var(--white);
  background-color: var(--secondary-color);
}