/* Sidebar Post Card */
.sidebar .post-item{
    display: -webkit-box; 
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem; 
    margin-bottom: 0.5rem;
}  

/* Sidebar Post Last child */
.sidebar .post-item:last-child{
    margin-bottom: 0;
}

/* Sidebar Post Image */
.sidebar .post-item img{
    height: 8rem;
    width: 10rem;
    object-fit: cover;
    background-color: var(--grey);
}

/* Sidebar Post Heading */
.sidebar .post-item a{
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
}

/* Sidebar Post Heading, On Hover */
.sidebar .post-item a:hover{
    color: var(--main-color);
}

/* Sidebar Post Date */
.sidebar .post-item h3{
    padding-top: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.4rem;
    color: var(--grey);
}

/* Sidebar Post Date Icon */
.sidebar .post-item h3 .icon{
    color: var(--main-color);
}

/* Sidebar Post Date Text */
.sidebar .post-item h3 span{
    font-size: 1.3rem;
    font-weight: 400;
}