/* Blog List Container */
.blog.list{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;  
    gap: 2rem;  
}   

/* Blog Card */
.blog-container.list .blog-item{
    width: 100%;
    margin-bottom: 1rem;
}

/* Blog Image */
.blog-container.list .blog-item .image{
    height: 30rem;
}

/* "No Blog Found" Message */
.no-found{
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--secondary-color);
    text-transform: none;
}