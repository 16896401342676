/* Service Single */
.service-single{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
        align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
    gap: 1.5rem; 
}  
 
/* Service Info */
.service-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 75rem;
        flex: 1 1 75rem;
}

/* Service Image Container */
.service-info .image{
    height: 40rem; 
}

/* Service Image */
.service-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Service Heading */
.service-info .main-heading{
    display: inline-block; 
    font-size: 4rem;
    font-weight: 600;
    color: var(--black);
    padding-top: 2rem;
    padding-bottom: 1rem;
}

/* Service Paragraph */
.service-info p{
    padding-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5; 
}

/* Service Gallery */
.service-info .service-gallery .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

/* Service Gallery Image */
.service-info .service-gallery img{
    height: 25rem;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

/* Service Sub Heading */
.service-info .sub-heading{
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}

/* Service List */
.service-info ul{
    list-style: none;
}

/* Service List Item */
.service-info ul li{
    padding-bottom: 1rem;
    font-size: 1.7rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    gap: 1rem;
}

/* Service Last List Item */
.service-info ul li:last-child{
    padding-bottom: 0;
}

/* Service List Item Icon */
.service-info ul li .icon{ 
    color: var(--main-color);
}