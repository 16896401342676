/*--------------------- 0- Global CSS (Start) ---------------------*/

/* Importing fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');

/* Global CSS variables */
:root{ 
    --main-color: #DC0000; 
    --secondary-color: #000B49; 
    --black: #000;
    --white: #fff;
    --grey: #666; 
    --border-radius: 1rem;
    --border: 0.1rem solid rgba(0, 0, 0, 0.1);
    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

/* Global styles for all elements */
*{
    font-family: 'Poppins', sans-serif;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: capitalize;
    border: none;
    outline: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

/* Text selection */
*::-moz-selection{
    color: var(--white);
    background-color: var(--main-color);
}
*::selection{
    color: var(--white);
    background-color: var(--main-color);
}

*::-moz-selection{
    color: var(--white);
    background-color: var(--main-color);
}

*::selection{
    color: var(--white);
    background-color: var(--main-color);
}

/* Body */
body{
    background-color: var(--white);
    overflow-x: hidden;
}

/* Html */
html{
    scroll-padding-top: 8rem;
    font-size: 62.5%;
    overflow-x: hidden;
}

/* Sections */
section{
    padding: 3rem 5%;
}

/* Scrollbar styles */
::-webkit-scrollbar{
    width: 0.8rem;
}

::-webkit-scrollbar-track{
    background: var(--black);
}

::-webkit-scrollbar-thumb{
    background: var(--main-color);
}


/*----- 1- Form CSS -----*/ 
.form{
    background: var(--white);
}

/* Autofill input fields */
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--black);
    -webkit-transition: background-color 5000s ease-in-out 0s;
            transition: background-color 5000s ease-in-out 0s;
}
  
/* Form heading */
.form h3{ 
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 0rem;
    color: #333;
    text-transform: uppercase;
    text-align: left;
}
 
/* Form input box container */
.form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
  
/* Form Input box inside input box container */
.form .input-box .box{
    width: 49%;
}
  
/* Input box */
.form .box{
    width: 100%;
    background-color: transparent;
    font-size: 1.6rem;
    color: var(--grey);
    padding: 1rem 1.5rem; 
    margin: 0.7rem 0;
    border: 0.15rem solid rgba(0, 0, 0, 0.1);
    text-transform: none;
}

/* Input box placeholder */
.form .box::-webkit-input-placeholder{
    text-transform: capitalize;
}
.form .box::-moz-placeholder{
    text-transform: capitalize;
}
.form .box:-ms-input-placeholder{
    text-transform: capitalize;
}
.form .box::placeholder{
    text-transform: capitalize;
}

/* Focused Input box */
.form .box:focus{
    border-color: var(--main-color);
}

/* Form Textarea */
.form textarea{
    resize: none;
    height: 20rem;
} 

/* Form button */
.form button{
    width: 100%;
    margin-top: 1rem;
    border-radius: 0.5rem;
}


/*----- 2- Account-Form CSS -----*/
.account-form{
    max-width: 45rem;
    padding: 5rem;
    background: var(--black);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    margin: 1rem auto;
    text-align: center;
    border-bottom: 0.5rem solid var(--main-color);
    position: relative;
    margin-top: 4rem;
}

/* Account Form Autofill input fields */
.account-form input:-webkit-autofill,
.account-form input:-webkit-autofill:hover,
.account-form input:-webkit-autofill:focus,
.account-form input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--white);
    -webkit-transition: background-color 5000s ease-in-out 0s;
            transition: background-color 5000s ease-in-out 0s;
}

/* Account form Icon */
.account-form .icon{
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    font-size: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--main-color);
    color: var(--white);
}
  
/* Account form heading */
.account-form h3{ 
    font-size: 3rem;
    padding: 1rem 0;
    font-weight: 500;
    color: var(--white);
    text-transform: uppercase;
}

/* Account form input field container */
.account-form .input-field{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    margin: 0.7rem 0;
    border: 0.1rem solid rgba(255, 255, 255, 0.5);
}

/* Account form input field container, On Hover */
.account-form .input-field:hover{
    border-color: var(--main-color);
}

/* Account form input field label */
.account-form .input-field label{
    color: var(--white);
    background-color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 6rem;
    font-size: 1.8rem;
    cursor: pointer;
}
  
/* Account form Input box */
.account-form .box{
    width: 100%;
    background-color: transparent;
    font-size: 1.6rem;
    color: var(--white);
    padding: 1rem 0;
    text-transform: none;
}

/* Input box placeholder */
.account-form .box::-webkit-input-placeholder{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
}
.account-form .box::-moz-placeholder{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
}
.account-form .box:-ms-input-placeholder{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
}
.account-form .box::placeholder{
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
}

/* Account form text */
.account-form p{
    padding-top: 2rem;
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    padding-bottom: 1rem;
}

/* Account form button */
.account-form .btn{
    width: 100%;
    overflow: hidden;
    margin: 1rem 0;
    border-radius: 0.5rem;
    border: 0.2rem solid var(--white);
}

/* Account form link text */
.account-form .link{
    font-size: 1.5rem;
    color: var(--main-color);
}

/*  Account form link text, On Hover */
.account-form .link:hover{
    text-decoration: underline;
}


/*----- 3- Sidebar CSS -----*/
/*
1- Search
2- Category
3- Recent Items
4- Tags
*/

/* sidebar container */
.sidebar{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem; 
        flex: 1 1 25rem;  
}

/* Sidebar item */
.sidebar .sidebar-item{
    margin-bottom: 1.5rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border: var(--border);
}

/* Sidebar item container */
.sidebar .box-container{
    padding: 1.5rem;
}

/*-- 1- Search --*/
/* Search input box */
.sidebar .search .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
}

/* Search input */
.sidebar .search .input-box input{
    width: 100%;
    padding: 1.5rem;
    overflow: hidden;
}

/* Search Autofill input fields */
.sidebar input:-webkit-autofill,
.sidebar input:-webkit-autofill:hover,
.sidebar input:-webkit-autofill:focus,
.sidebar input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--black);
    -webkit-transition: background-color 5000s ease-in-out 0s;
            transition: background-color 5000s ease-in-out 0s;
}

/* Focused search input */
.sidebar .search .input-box input:focus{
    border-color: var(--main-color);
}

/* Sidebar Search button */
.sidebar .search .input-box button{
    color: var(--white);
    background-color: var(--secondary-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
}

/* Sidebar Search button, On Hover */
.sidebar .search .input-box button:hover{
    background-color: var(--main-color);
}

/*-- 2- Category --*/
/* Category item */
.sidebar .category a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 0.15rem solid rgba(0, 0, 0, 0.1);
}

/* Category item text */
.sidebar .category a span{
    font-size: 1.7rem;
    color: var(--black);
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
}

/* Category item icon */
.sidebar .category a span .icon{
    height: 2rem;
    width: 2rem;
    color: var(--main-color);
}

/* Category item Quantity */
.sidebar .category a p{
    font-size: 1.5rem;
    color: var(--grey);
}

/* Category item Text, On Category Item Hover */
.sidebar .category a:hover span,
.sidebar .category a.active span{
    color: var(--main-color);
    margin-left: 1rem;
}

/* Category item Quantity, On Category Item Hover */
.sidebar .category a:hover p,
.sidebar .category a.active p{
    color: var(--main-color);
}

/*-- 3- Tags --*/
/* Sidebar Tag Container */
.sidebar .tags .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

/*  Tag item */
.sidebar .tags a{
    color: var(--grey);
    border: 0.15rem solid rgba(0, 0, 0, 0.1);
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
}

/* Tag item, On Hover */
.sidebar .tags a:hover,
.sidebar .tags a.active{
    background-color: var(--main-color);
    color: var(--white);
    cursor: pointer;
}


/*----- 4- Swiper Pagination CSS -----*/
/* Swiper pagination bullet */
.swiper-pagination-bullet{ 
    height: 1rem;
    width: 3rem;
    background: #999;
    border: var(--border);
    opacity: 0.7;
    border-radius: 0.2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Active swiper pagination bullet */
.swiper-pagination-bullet-active{
    width: 6rem;
    background: var(--main-color) !important;
    opacity: 0.9 !important;
}


/*--------------------- Responsive (Start) ---------------------*/
/* Responsive screens up to 991px */
@media (max-width: 991px){

    /* Adjust the font size for HTML elements */
    html{
      font-size: 55%;
    }
  
    /* Enable reverse wrapping for blog grid and list items */
    .blog.grid, .blog.list{
      -ms-flex-wrap: wrap-reverse;
          flex-wrap: wrap-reverse;
    }
  
  }
  
  /* Responsive screens up to 768px */
  @media (max-width: 768px){
  
    /* Adjust padding for 1st header */
    .header .header-1{
        padding: 2rem;
    }

    /* Adjust padding for 2nd header */
    .header .header-2{
        padding: 0rem 2rem;
    }

    /* Adjust padding for sections */
    section{
        padding: 3rem 2rem;
    }

    /* Adjust padding for the footer */
    .footer .box-container{
        padding: 2rem;
    }
  
    /* Adjust padding for the content within home items */
    .home-item .content{ 
        padding: 2rem 10%;
    }
  
    /* Categories Container */
    .categories .box-container{
        -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        'category-1 category-1'
        'category-2 category-3'
        'category-4 category-5'; 
    }

    /* Adjust flex-wrap for car item in list view */
    .cars .car-container.list .car-item{
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    /* Car item Image in list view */
    .cars .car-container.list .car-item .image{
        width: 100%;
    }

    /* Car item Image in list view */
    .cars .car-container.list .car-item .image img{
        height: 30rem;
    }

    /* Car Review */
    .car-info .car-review {
        width: 100%;
    }

    /* Contact Container */
    .contact .box-container{
        padding: 2rem;
    }
  
  }
  
  @media (max-width: 450px){
  
    /* Reduce the font size of the HTML element */
    html{
        font-size: 50%;
    }
  
    /* Adjust padding for 1st header */
    .header .header-1{
        padding: 1rem;
    }

    /* Adjust padding for 2nd header */
    .header .header-2{
        padding: 0rem 1rem;
    }

    /* Adjust padding for sections */
    section{
        padding: 3rem 1rem;
    }

    /* Adjust padding for the footer */
    .footer .box-container{
        padding: 1rem;
    }
  
    /* Adjust padding for the content within home items */
    .home-item .content{ 
      padding: 2rem 14%;
    }
  
    .categories .box-container{
        -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
        grid-template-areas: 
        'category-1'
        'category-2'
        'category-3'
        'category-4'
        'category-5';
    }
  
    /* Adjust width for input boxes within forms */
    .form .input-box .box{
      width: 100%;
    }
  
    /* Adjust width for cart summary */
    .cart .cart-total{
      width: 100%;
    }

    /* Contact Container */
    .contact .box-container{
        padding: 1rem;
    }

    /* Contact form Input Box Container */
    .contact-form .input-box{
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    /* Contact form Input field Inside Input Box */
    .contact-form .input-box .box{
        width: 100%;
    }
  
  }
  
  /*--------------------- Responsive (End) ---------------------*/