/* Car Category Grid container */
.categories .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
    gap: 1rem; 
    grid-template-areas:  
    'category-1 category-1 category-2'
    'category-3 category-4 category-5';
}
 
/* 1st category */
.category-1 {
    grid-area: category-1;
}

/* 2nd category */
.category-2 {
    grid-area: category-2;
}

/* 3rd category */
.category-3 {
    grid-area: category-3;
}

/* 4th category */
.category-4 {
    grid-area: category-4;
}

/* 5th category */
.category-5 {
    grid-area: category-5;
}

/* Category Card */
.category-item{
    height: 25rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    overflow: hidden;
    border-radius: 0.5rem;
    position: relative;
    cursor: pointer;
}

/* Category Link */
.category-item a{
    content: '';
    position: absolute;
    top: 0; 
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}

/* Category Image */
.category-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* Category Image, On Category Card Hover */
.category-item:hover img{
    scale: 1.1;
}

/* Category Heading */
.category-item h3{ 
    position: absolute;
    left: 0%;
    bottom: 0%;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--white);
    padding: 1.5rem 2rem;
}