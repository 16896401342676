.featuredCar-slider {
    /* Set the container to hide any overflowing content */
    overflow: hidden;
    position: relative;
}
  
.featuredCar-slider .swiper {
    /* Allow the swiper element to overflow and be visible */
    overflow: visible !important;
    padding-bottom: 0.5rem; /* Add padding at the bottom of the swiper element */
}
