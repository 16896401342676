/* Footer Section */
.footer{
    background-color: var(--black);
}
 
/* Footer Box Container */
.footer .box-container{  
    display: -ms-grid; 
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2.5rem;
    padding: 2rem 5%;
} 
 
/* Footer Item Heading */
.footer-item h2{ 
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: 0.2rem;
    padding-bottom: 2rem;
}

/* Footer Item Paragraph */
.footer-item p{
    color: var(--white); 
    font-size: 1.6rem;
    line-height: 1.6;
}

/* 1st Footer Item Paragraph */
.footer-item > p{
    padding-top: 1rem;
    font-size: 1.5rem;
} 

/* Footer Social Contacts */
.footer-item .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}

/* Footer Social link */
.footer-item .social a{
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Footer Social Icon */
.footer-item .social a .icon{
    color: var(--white);
    font-size: 2.2rem;
}

/* Footer Social Icon, On Social Link Hover */
.footer-item .social a:hover .icon{
    color: var(--main-color);
}

/* Footer Pages */
.footer-item .pages{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

/* Footer Pages Items */
.footer-item .pages .links-item{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 15rem;
            flex: 1 1 15rem;
}

/* Footer Info item */
.footer-item .info p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

/* Footer Info Link */
.footer-item .info a{ 
    font-size: 1.6rem;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/* Footer Page Link Icon */ 
.footer-item .links p .icon{
    color: var(--main-color);
    height: 1.6rem;
    width: 1.6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/* Footer Page Link, On Link Hover */
.footer-item .links p:hover a{
    color: var(--main-color);
}

/* Footer Connect Icon */
.footer-item .connect .icon{
    font-size: 1.6rem;
    color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* Footer Item "gmail" class */
.footer-item .gmail{
    text-transform: none;
}

/* Footer Content */
.footer .content{
    text-align: center;
    padding: 2rem 0;
    background-color: var(--main-color);
}

/* Footer Content Paragraph */
.footer .content p{
    font-size: 2rem;
    color: var(--white);
}

/* Footer Content Span */
.footer .content p span{
    color: var(--white);
}