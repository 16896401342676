/* Counting Section */
.counting{
    padding: 4rem 5%;
    margin: 1rem 0;
    min-height: 15rem;   
    display: -ms-grid;  
    display: grid;
    -ms-grid-columns: (minmax(22rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    gap: 2rem;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
    background:         linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Counter.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

/* Count Box */
.counting .box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: var(--border-radius);
}

/* Count Icon */
.counting .box .icon{
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;  
    font-size: 6rem;
}

/* Count Number */
.counting .box .count{
    font-size: 4rem;
    font-weight: 500;
    color: var(--white);
    line-height: 1.2;
}

/* Count Name*/
.counting .box h3{
    font-size: 2rem;
    font-weight: 500;
    color: var(--white);
}