/* Gallery Tabs Button */
.gallery .controls .button{
    padding: 1rem 2.5rem;
    font-size: 2rem;
    color: var(--main-color);
    border: 0.15rem solid var(--main-color);
    -webkit-box-shadow: var(--box-shadow); 
            box-shadow: var(--box-shadow);
}
  
/* Gallery Tabs Button, On Hover & Active */
.gallery .controls .button:hover,
.gallery .controls .button.active{
    cursor: pointer;
    color: var(--white);
    background-color: var(--main-color);
}