/* Shop summary container */
.shop-summary{
    background-color: var(--white);
} 
 
/* Summary item */
.summary-item{
    display: -webkit-box;
    display: -ms-flexbox;  
    display: flex; 
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center; 
    -ms-flex-align: center; 
    align-items: center;
    padding-bottom: 1.5rem;
} 

/* Summary item boxes */
.summary-item .box{
    font-size: 2rem;
    color: var(--black);
}

/* Each Summary Item Name */
.summary-item .name{
    font-weight: 600;
}

/* Each Summary Item Value */
.summary-item .value{
    font-weight: 400;
    color: var(--grey);
}