/* Comment */
.comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem; 
  -webkit-box-align: start;
  -ms-flex-align: start; 
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0; 
}

/* Comment Image container */
.comment .comment-image {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  border: 0.5rem solid var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Comment Image */
.comment .comment-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} 

/* Comment content */
.comment .comment-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 35rem;
      flex: 1 1 35rem;
}

/* Comment Intro */
.comment .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 0.5rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 1rem;
}

/* Comment Name Container */
.comment .intro .name { 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40rem;
      flex: 1 1 40rem;
}

/* Author Name */
.comment .intro h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--black);
}

/* Dash separator */
.comment .intro span {
  height: 0.2rem;
  width: 2rem;
  background-color: var(--main-color);
}

/* Comment Date */
.comment .intro h6 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--white);
  background-color: var(--main-color);
  padding: 0.2em 0.5rem;
}

/* Comment rating */
.comment .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

/* Comment Star icon */
.comment .rating .icon {
  font-size: 1.6rem;
  color: var(--main-color);
}

/* Comment Rating value */
.comment .rating h5 {
  font-size: 1.6rem;
  color: var(--black);
}

/* Comment Text */
.comment .text{
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
  padding-bottom: 1rem;
}

/* Comment Reply */
.comment .icon-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.3rem;
}

/* Comment Reply icon */
.comment .icon-item .icon {
  color: var(--main-color);
  font-size: 1.5rem;
}

/* Comment Reply text */
.comment .icon-item span {
  color: var(--main-color);
  font-size: 1.6rem;
}

/* Comment Reply, On Hover */
.comment .icon-item:hover .icon,
.comment .icon-item:hover span {
  cursor: pointer;
  color: var(--secondary-color);
}