/* Blog Single */
.blog-single{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
    gap: 2rem;
} 

/* Blog Container */
.blog-single .blog-container{
    -webkit-box-flex: 1; 
    -ms-flex: 1 1 75rem; 
        flex: 1 1 75rem;
}

/*----- 1- Blog Info -----*/
/* Blog Image container */
.blog-info .image{ 
    height: 40rem; 
    position: relative;
}
  
/* Blog Image */
.blog-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
  
/* Blog Content */
.blog-info .content{
    padding: 2rem 0;   
}
  
/* Blog Details */
.blog-info .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

/* Blog Category */
.blog-info .category{
    background-color: var(--main-color);
    color: var(--white);
    font-size: 1.6rem;
    text-align: center;
    padding: 0.5rem 1rem;
}

/* Blog Detail Item */
.blog-info .details h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.6rem;
    color: var(--grey);
}

/* Blog Detail Icon */
.blog-info .details h3 .icon{
    color: var(--main-color);
}

/* Blog Detail Text */
.blog-info .details h3 span{
    font-weight: 400;
}
  
/* Blog Heading */
.blog-info .content .main-heading{
    display: inline-block;
    font-size: 3.5rem;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}
  
/* Blog Paragraph */
.blog-info p{
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}
  
/* Blog Important Info */
.blog-info .important{
    padding: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--white);
    font-style: italic;
    line-height: 1.8;
    border-left: 0.5rem solid var(--main-color);
    margin-bottom: 2rem;
    margin-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    background-color: var(--black);
}

/* Blog Important Icon */
.blog-info .important .icon{
    font-size: 8rem;
    color: var(--main-color);
}

/* Blog Important Intro */
.blog-info .important .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

/* Blog Important Dash Symbol */
.blog-info .important .intro span{
    height: 0.2rem;
    width: 2rem;
    background-color: var(--main-color);
}

/* Blog Important Author Name */
.blog-info .important .intro h6{
    font-size: 2rem;
    color: var(--white);
    font-style: normal;
}

/* Blog Sub Heading */
.blog-info .sub-heading{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 0.2rem; 
}

/* Blog Gallery */
.blog-info .blog-gallery .box-container{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

/* Blog Gallery Image */
.blog-info .blog-gallery img{ 
    height: 25rem;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

/* Blog Points List */
.blog-info .blog-points ul{
    list-style-type: none;
}

/* Blog Point */
.blog-info .blog-points ul li{
    font-size: 1.5rem;
    color: var(--grey);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

/* Blog Point Icon */
.blog-info .blog-points ul li .icon{
    height: 1.5rem;
    width: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--main-color);
}

/* Blog End Details */
.blog-info .end-details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 2rem;
    margin-top: 1rem;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
}
  
/* Blog Tags and Share Container */
.blog-info .tags, 
.blog-info .share{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.7rem;
}
  
/* Blog Share Heading */
.blog-info .share h3{
    color: var(--black);
    font-size: 1.6rem;
    padding-right: 1rem;
}
  
/* Blog Tags Link */
.blog-info .tags a{
    color: var(--grey);
    font-size: 1.4rem;
    padding: 0.7rem 1.2rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
}

/* Blog Tags Link, On Hover */
.blog-info .tags a:hover{
    color: var(--white);
    background-color: var(--main-color);
}
  
/* Blog Share Icon */
.blog-info .share .icon{
    height: 3rem;
    width: 3rem;
    padding: 0.7rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center;
    color: var(--white);
    background-color: var(--main-color);
    font-size: 1.4rem;
}
  
/* Blog Share Icon, On Hover */
.blog-info .share .icon:hover{
    background-color: var(--secondary-color);
    cursor: pointer;
}  