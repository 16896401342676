/* Shopping cart */
.cart .shopping-cart{
    margin-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden; 
    border: var(--border);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}   
  
/* Shopping cart Scrollbar */ 
.shopping-cart::-webkit-scrollbar{
    height: 1rem;
}
 
/* Shopping cart Container */
.cart .shopping-cart .container{
    min-width: 90rem;  
}
 
/* cart Container */
.cart .box-container{
    padding: 0rem 1rem;
}

/* Cart item */
.cart-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
    gap: 3rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
} 

/* Cart item last child */
.cart-item:last-child{
    border: none;
}

/* Cart Item Box */
.cart-item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 10rem;
        flex: 1 1 10rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    color: var(--black);
}

/* Cart Item 1st Box */
.cart-item .car{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem; 
        flex: 1 1 40rem;  
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    gap: 2rem;
}

/* Car Image */
.cart-item img{
    height: 10rem; 
    width: 20rem;
    object-fit: cover;
    background-color:#f7f7f7;
    border-radius: 1rem;
}

/* Car Name */
.cart-item .name{
    color: var(--black);
    font-weight: 500;
}

/* Car Price */
.cart-item .price{
    color: var(--grey);
}

/* Car Action */
.cart-item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cart-item button{
    background-color: transparent;
}

/* Cart Delete Icon */
.cart-item .icon{
    color: red;
    font-size: 2rem;
    cursor: pointer;
}

/* Cart Delete Icon, On Hover */
.cart-item .icon:hover{
    color: var(--secondary-color);
}

/* Cart total */
.cart .cart-total{
    width: 45rem;
    margin-left: auto;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    padding: 1rem;
    border: var(--border);
    border-top: 0.3rem solid var(--main-color);
}

/* Cart total Button */
.cart .cart-total .btn{
    width: 100%;
    text-align: center;
    margin-top: 1rem;
} 

/* "No Cart Item Found" Message */
.cart .no-cart-items {
    min-height: 80vh; /* Set minimum height for no cart items */
}

/* Empty cart message */
.cart .no-cart-items .empty-cart-msg {
    background-color: var(--black);
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 1rem;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    -webkit-transition: none;
    transition: none;
}

/* Empty cart message Icon */
.cart .no-cart-items .empty-cart-msg .icon {
    font-size: 2rem;
}

/* Empty cart message Heading */
.cart .no-cart-items .empty-cart-msg h3 {
    font-size: 2rem;
    font-weight: 500;
    color: var(--white);
}