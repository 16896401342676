/* Aligning About content in the center */
.about .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    position: relative;
    min-height: 80vh;  
} 
 
/* About Image */
.about img{
    height: 80vh;
    width: 100%;
    object-fit: cover;
}

/* About content */
.about .content{
    padding: 2rem 4rem;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    max-width: 70rem;
    padding-left: 15rem;
    background-color: rgba(220, 0, 0, 0.8);
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 20% 100%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* About Section Heading */
.about .heading{
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    padding-bottom: 0;
}

/* About Section Heading (Sub Text) */
.about .heading span{
    color: var(--white);
}

/* About Heading */
.about .content h3{
    font-size: 4rem;
    font-weight: 600;
    color: var(--white);
    line-height: 1.3;
    padding-bottom: 1rem;
}

/* About Paragraph */
.about .content p{
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: var(--white);
    line-height: 1.6;
    padding-bottom: 2rem;
}

/* About Button */
.about .btn{
    border: 0.15rem solid var(--white);
}