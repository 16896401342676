/* Contact Container */
.contact .box-container{
    padding: 2rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;  
    gap: 4rem; 
}   
  
/*----- 1- Google Map -----*/
.contact iframe{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem; 
        flex: 1 1 40rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/*----- 2- Contact Info -----*/
.contact-info{ 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    padding: 3rem 0;
}

/* Contact info item */
.contact .contact-info .info-item{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
        flex: 1 1 33rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
}

/* Contact Info Icon */
.contact .contact-info .info-item .icon{
    height: 7rem;
    width: 7rem;
    font-size: 3rem;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--main-color);
    color: var(--white);
}

/* Contact Info Heading */
.contact .contact-info .info-item h3{
    font-size: 2.5rem;
    color: var(--black);
    padding-bottom: 0rem;
}

/* Contact Info Text */
.contact .contact-info .info-item p{
    font-size: 1.4rem;
    color: var(--grey);
    padding-bottom: 0.5rem;
    text-transform: none;
}

/*----- 3- Contact Form -----*/
/*-- Contact Form --*/
.contact-container{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

/* Contact form heading */
.contact h3{
    color: var(--black);
    font-size: 4rem;
    padding-bottom: 2rem;
}

.contact-form input:-webkit-autofill,
.contact-form input:-webkit-autofill:hover,
.contact-form input:-webkit-autofill:focus,
.contact-form input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--black); /* Set the text color for autofill inputs */
    -webkit-transition: background-color 5000s ease-in-out 0s; /* Apply a transition to the background color for a smooth effect */
    transition: background-color 5000s ease-in-out 0s;
}

/* Contact form Input Box Container */
.contact-form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
  
/* Contact form Input field Inside Input Box */
.contact-form .input-box .box{
    width: 49%;
}

/* Contact form Input field */
.contact-form .box{
    width: 100%;
    font-size: 1.6rem;
    color: var(--black);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    background-color: transparent;
    text-transform: none;
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem;
}

/* Contact form Input field, Focused */
.contact-form .box:focus{
    border-color: var(--main-color);
}

/* Contact form Input field, Placeholder text */
.contact-form .box::placeholder{
    text-transform: capitalize;
    color: var(--grey);
}

/* Contact form Messge Field */
.contact-form textarea.box{
    height: 20rem;
    resize: none;
}

/* Contact form Alert container */
.contact-form .alert{
    font-size: 2rem;
    color: var(--main-color);
    padding-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

/* Contact form Alert Message */
.contact .msg-alert{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}