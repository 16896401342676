/* Testimonials section */
.testimonials{
    overflow: hidden;
}  

/* Testimonial slider */
.testimonial-slider{ 
    overflow: hidden; 
    position: relative; 
}
  
/* Testimonial slider swiper container */
.testimonial-slider .swiper{
    overflow: visible !important;
    padding-bottom: 6rem; 
    padding-top: 6rem;  
} 

/* Testimonial */
.testi-item{
    padding: 2rem;
    padding-top: 10rem;
    border: var(--border);
    position: relative;
    text-align: center;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

/* Testimonial Image */
.testi-item img{
    position: absolute;
    top: 5%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    object-fit: cover;
    outline: 0.2rem solid var(--white);
    outline-offset: -0.5rem;
}

/* Testimonial Icon */
.testi-item .quote-left{
    font-size: 6rem;
    color: var(--main-color);
}

/* Testimonial Comment */
.testi-item p{
    font-size: 1.5rem;
    line-height: 1.6;
    padding: 1.5rem 0;
    color: var(--grey);
}

/* Author Name */
.testi-item h4{
    font-size: 2rem;
    color: var(--secondary-color);
    font-family: 'Sofia', cursive;
}

/* Author Title */
.testi-item h6{
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--main-color);
}



/* Testimonials section Heading (On the homepage) */
.homepage .testimonials .heading span,
.homepage .testimonials .heading h2{
    color: var(--white);
}

/* Testimonials section (On the homepage) */
.homepage .testimonials{
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Testimonials.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../../assets/Background/Testimonials.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
} 