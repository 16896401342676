/* FAQ section */
.faq{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;   
    -ms-flex-align: center;  
    align-items: center; 
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 2rem;
}  

/* FAQ Accordion container */
.faq .accordion-container {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
}

/* FAQ Image container */
.faq .image{
    height: 50rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

/* FAQ Image */
.faq .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}