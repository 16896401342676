/* Title container */
.cartlist-title{
  background-color: var(--black);
  color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-pack: center; 
  -ms-flex-pack: center; 
  justify-content: center;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem; 
} 

/* Individual titles */
.cartlist-title h3{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 10rem;
      flex: 1 1 10rem;
  font-size: 1.8rem;
  text-align: center;
}

/* First title */
.cartlist-title h3:first-child{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40rem;
      flex: 1 1 40rem;
  text-align: left;
}