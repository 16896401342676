/* Heading container */
.heading{ 
  padding-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column; 
          flex-direction: column; 
}

/* Heading container Span */
.heading span{
  letter-spacing: 0.2rem;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--main-color); 
  font-family: 'Sofia', cursive;
}

/* Heading container title */
.heading h2{ 
  font-weight: 500;
  font-size: 4rem;  
  color: var(--secondary-color);
  text-transform: capitalize;
  position: relative;
}