/* Car Container (Grid) */
.cars .car-container.grid{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(33rem, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
    grid-gap: 1rem; 
}
  
/* "No Car found" Message */ 
.no-car-found{ 
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: var(--secondary-color);
}