/* Main navigation link */
.mobile-navbar .main-nav-link{
    position: relative; 
} 

/* Main navigation button */
.mobile-navbar .main-nav-link .nav-btn{
    background-color: var(--white);
    color: var(--black);
    font-size: 2rem;
    font-weight: bold;  
    cursor: pointer;  
    width: 100%;
    padding: 1.5rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify; 
    justify-content: space-between; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1rem;
}

/* Main navigation button, On Link Hover */
.mobile-navbar .main-nav-link:hover .nav-btn,
.mobile-navbar .main-nav-link.active .nav-btn{
    background-color: var(--main-color);
    color: var(--white);
}

/* Main navigation icon */
.mobile-navbar .main-nav-link .icon{
    position: absolute;
    top: 50%;
    right: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 2rem;
    cursor: pointer;
}

/* Main navigation icon, On Link Hover */
.mobile-navbar .main-nav-link:hover .icon,
.mobile-navbar .main-nav-link.active .icon{
    color: var(--white);
}

/* Sub-navigation container */
.mobile-navbar .sub-nav-link {
    padding-left: 2rem;
    padding-bottom: 1rem;
    background: var(--white);
}

/* Sub-navigation link */
.mobile-navbar .sub-nav-link a{
    color: var(--grey);
    display: block;
    padding: 1rem 0rem; 
    font-size: 1.8rem;
    font-weight: 500;
    border-bottom: 0.2rem solid rgba(0, 0, 0, 0.2);
}

/* Sub-navigation link, On Hover */
.mobile-navbar .sub-nav-link a:hover{
    color: var(--main-color);
    border-color: var(--main-color);
}