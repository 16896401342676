/* Faq accordion */
.faq .accordion{
    margin-bottom: 1.5rem;
}  
 
/* Faq last accordion */
.faq .accordion:last-child{ 
    margin-bottom: 0rem; 
}   

/* Faq accordion heading */ 
.faq .accordion .accordion-heading{
    padding: 1.5rem 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center; 
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    border-bottom: 0.15rem solid rgba(0, 0, 0, 0.1);
    color: var(--black); 
    cursor: pointer;
}

/* Faq accordion heading, On Accordion Expanded */
.faq .accordion.active .accordion-heading{
    color: var(--main-color);
}

/* Faq accordion heading title */
.faq .accordion .accordion-heading h3{
    font-size: 2rem;
}

/* Faq accordion heading icon */
.faq .accordion .accordion-heading .icon{
    height: 3rem;
    width: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--white); 
    background-color: var(--black);
    font-size: 1.6rem;
}

/* Faq accordion heading icon, On Accordion Expanded */
.faq .accordion.active .accordion-heading .icon{
    background-color: var(--main-color);
}

/* Faq accordion content, On Accordion Expanded */
.faq .accordion.active .accordion-content{
    display: block;
}

/* Faq accordion content */
.faq .accordion-content{
    padding: 1.5rem;
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
    display: none;
}