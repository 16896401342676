/* Gallery Card */
.gallery-item{
    position: relative;
    margin-bottom: 1rem;
}

/* Gallery Card image */
.gallery-item img{
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
}

/* Gallery Card content */
.gallery-item .content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
}

/* Gallery Card content Overlay, On Hover */
.gallery-item:hover .content{
    opacity: 1;
}

/* Gallery Card Title */
.gallery-item .content h4{
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: var(--white);
    padding-bottom: 1rem;
}

/* Gallery Card expand icon */
.gallery-item a .icon{
    scale: 0;
    padding: 1.2rem;
    color: var(--white);
    background-color: var(--main-color);
    height: 5rem;
    width: 5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    cursor: pointer;
} 

/* Gallery Card expand icon, On Gallery Card Hover */
.gallery-item:hover a .icon{
    scale: 1;
} 

/* Gallery Card expand icon, On Hover */
.gallery-item a:hover .icon{
    background-color: var(--secondary-color);
}